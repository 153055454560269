<script>
import { awards } from "@/config/api/awards";
import { getFormData } from "@/utils/createForm";

/**
 * Add Award Component
 */
export default {
  props: ["selectedAward", "awardData", "type"],
  data() {
    return {
      title: "User Awards",
      items: [
        {
          text: "All Awards",
          to: { path: `/Awards` },
        },
        {
          text: "Add",
          active: true,
        },
      ],
      selectedUserId: null,
      award_document: null,
      user_id: null,
      award_image: null,
      isFeatured: null,
      is_verified: false,
      loading: false,
      values: {},
      schema: [
        {
          index: 1,
          type: "text",
          name: "name",
          label: "Award Name",
          "validation-messages": { required: "Award name is required" },
          validation: "required",
        },
        {
          index: 2,
          type: "textarea",
          name: "description",
          label: "Description",
        },
        {
          component: "div",
          class: "row",
          children: [
            {
              index: 3,
              type: "select",
              name: `${this.type}_id`,
              class: "col-6",
              label: "Award recieved to",
              "validation-messages": {
                required: `Please choose a ${this.type}`,
              },
              validation: "required",
              options: this.awardData,
            },
            {
              index: 4,
              type: "date",
              name: "date_received",
              class: "col-6",
              label: "Date recieved",
              "validation-messages": { required: "Date received is required" },
              validation: "required",
            },
          ],
        },
      ],
    };
  },
  computed: {},

  methods: {
    async submitHandler(data) {
      if (this.selectedAward) {
        if (data.award_document) {
          data.award_document = data.award_document[0].location;
        }
        this.updateAward(data, this.selectedAward._id);
      } else {
        const pre_data = {
          ...data,
          file: this.award_document.fileList[0],
          is_verified: this.is_verified,
        };

        const formdata = getFormData(pre_data);
        this.addAward(formdata);
      }
    },

    addAward(data) {
      this.loading = true;

      const api = this.getAddApi();
      api.data = data;
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Award added successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
          this.$emit("closeModal");
        })
        .catch((err) => {
          this.$bvToast.toast("Award is not added!", {
            title: "Create",
            variant: "danger",
            solid: true,
          });
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getUpdateApi() {
      if (this.type === "project") {
        return awards.projects.update;
      } else if (this.type === "company") {
        return awards.companies.update;
      } else if (this.type === "user") {
        return awards.users.update;
      } else {
        console.error("Invalid type");
        return null;
      }
    },
    getAddApi() {
      if (this.type === "project") {
        return awards.projects.create;
      } else if (this.type === "company") {
        return awards.companies.create;
      } else if (this.type === "user") {
        return awards.users.create;
      } else {
        console.error("Invalid type");
        return null;
      }
    },
    updateAward(data, id) {
      this.loading = true;
      const api = this.getUpdateApi();
      api.data = { ...data, is_verified: this.is_verified };
      api.id = id;
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Award updated successfully!", {
            title: "Update",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
        })
        .catch((err) => {
          this.$bvToast.toast("Award is not updated!", {
            title: "Update",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.loading = false;
          this.$emit("closeModal");
        });
    },
    closeModal() {
      (this.award_document = null), (this.values = {});
      this.$emit("resetModal");
    },
  },
  watch: {
    selectedAward(val) {
      if (val) {
        this.values = {
          ...val,
          date_received: val.date_received
            ? this.moment(val.date_received).format('YYYY-MM-DD')
            : null,
        };
        this.values[`${this.type}_id`] = val[`${this.type}_id`]._id;
        this.award_document = [{ url: val.award_document }];
        this.is_verified = val.is_verified;
      }
    },
  },
};
</script>

<template>
  <b-modal
    id="action-Award"
    size="md"
    title-class="font-18"
    @hidden="closeModal"
    :title="selectedAward ? 'Update Award' : 'Add Award'"
    hide-footer
  >
    <FormulateForm
      name="AwardAction"
      v-model="values"
      :schema="schema"
      @submit="submitHandler"
    >
      <div class="row">
        <div class="col-8">
          <FormulateInput
            type="image"
            name="award_document"
            v-model="award_document"
            label="Upload Award Document"
            help="Select a png or jpg to upload."
            :validation="
              $route.query.id
                ? ''
                : 'required:mime:image/jpeg,image/png,image/webp'
            "
          />
        </div>

        <div class="col-4 pt-2">
          <b>{{ is_verified ? "Verified" : "Not Verified" }}</b>
          <b-form-checkbox
            v-model="is_verified"
            name="is_verified"
            switch
            size="lg"
          >
          </b-form-checkbox>
        </div>
      </div>

      <FormulateInput type="submit" class="float-right">
        <div v-if="loading">
          <b-spinner class="vueformulate-loader"></b-spinner>
          <span v-if="selectedAward"> Updating... </span>
          <span v-else> Adding... </span>
        </div>
        <div v-else>
          <span v-if="selectedAward"> Update </span>
          <span v-else> Add </span>
        </div>
      </FormulateInput>
    </FormulateForm>
  </b-modal>
</template>
